import React, { useState } from 'react'
import Button from '../../common/button/Button'
import LineFlex from '../../layout/line-flex.layout/LineFlex'
import { GoSync } from 'react-icons/go';
import {  SyncAllCategoriesService, SyncAllEventsService, SyncEventByIdService } from '../../../../services/events.service';
import Details from '../../layout/details/Details';
import PageHeader from '../../common/page-header/PageHeader';
import TextField from '../../common/text-field/TextField';

export default function ParameterControl() {

  // # all events sync
  const [syncEvents, setSyncEvents] = useState<boolean>(false)
  const [syncEventsFeedback, setSyncEventsFeedback] = useState({success: false, message: ''})

 // # all event by id sync.
  const [syncEventById, setSyncEventById] = useState<boolean>(false)
  const [syncEventByIdFeedback, setSyncEventByIdFeedback] = useState({success: false, message: ''})
  const [objectId, setObjectId] = useState({id: '', error: true})

  const [syncCategories, setSyncCategories] = useState<boolean>(false)
  const [syncCategoriesFeedback, setSyncCategoriesFeedback] = useState({success: false, message: ''})

  const syncEventsHandler = async () => {

    // # Add spinner and reset feedback
    setSyncEvents(!syncEvents)
    setSyncEventsFeedback({success: false, message: ''})

    // # sync all events from myBiz to MongoDB.
    SyncAllEventsService()
    .then(res => {
      setSyncEvents(false)
      setSyncEventsFeedback({success: true, message: 'פעולת הסנכרון הושלמה בהצלחה!'})
    
    })
    .catch(err => {
      setSyncEvents(false)
      setSyncEventsFeedback({success: false, message: 'שגיאה בסינכרון אירועים'})
    })
    
  }
  const syncCategoriesHandler = async () => {

    // # Add spinner and reset feedback
    setSyncCategories(!syncEvents)
    setSyncCategoriesFeedback({success: false, message: ''})

    // # sync all events from myBiz to MongoDB.
    SyncAllCategoriesService()
    .then(res => {
      setSyncCategories(false)
      setSyncCategoriesFeedback({success: true, message: 'פעולת הסנכרון הושלמה בהצלחה!'})
      console.log(res);
     
    })
    .catch(err => {
      setSyncCategories(false)
      setSyncCategoriesFeedback({success: false, message: 'שגיאה בסינכרון קטגוריות'})
    })
    
  }
  const syncEventByIdHandler = async () => {

    if(objectId.error) return setSyncEventByIdFeedback({success: false, message: 'חובה להזין ObjectId של האירוע.'});

    // # Add spinner and reset feedback
    setSyncEventById(!syncEventById)
    setSyncEventByIdFeedback({success: false, message: ''})

    // # sync all events from myBiz to MongoDB.
    let eventId = objectId.id
    SyncEventByIdService(eventId)
    .then(res => {
      setSyncEventById(false)
      if( res.message ) {
        setSyncEventByIdFeedback({success: true, message: res.message})
      } else {
        setSyncEventByIdFeedback({success: false, message: res})
      }
      
    })
    .catch(err => {
      setSyncEventById(false)
      setSyncEventByIdFeedback({success: false, message: 'שגיאה בסינכרון האירוע'})
    })
  }

  const buttonEventSyn_conditionalRendering = (type: string) => {
    if( type === 'sync-event-by-objectId' ) {
      if(! syncEventById) {
        return 'סנכרן אירוע יחיד'
      } else {
        return ' מסנכרן אירוע יחיד נא להמתין...'
      }
    }
    if( type === 'sync-all-events' ) {
      if(! syncEvents) {
        return 'סנכרן אירועים'
      } else {
        return ' מסנכרן אירועים נא להמתין...'
      }
    }
    if( type === 'sync-all-categories' ) {
      if(! syncCategories) {
        return 'סנכרן קטגריות'
      } else {
        return ' מסנכרן קטגריות נא להמתין...'
      }
    }
  }

  return (
    <div className='parameter-control'>
      <PageHeader>פרמטרים</PageHeader>

      {/* sync event by objectId */}
      <LineFlex pX='0.5vw' pY='3.5vw 0' flexDir="column">
        <LineFlex pX='2vw'>
          <Details title="סנכרון אירוע בודד לפי EventID" >
          <p>פעולה זו תבצע סנכרון מלא של אירוע יחיד במערכת  מ-מערכת MyBussiness ל-בסיס הנתונים הנוכחי</p>
          <br />
          <p>יש להזין תחילה EventID של האירוע המתבקש ולאחר מכן לבצע סינכרון</p>
          </Details>
        </LineFlex>

        <LineFlex>
          <span onClick={() => syncEventByIdHandler()}>
              <Button color='primary' disabled={syncEventById && true} disabledEffect={false}>
                {buttonEventSyn_conditionalRendering('sync-event-by-objectId')}
                <span 
                  className={`parameter-control__on-action ${!syncEventById && 'parameter-control__sync-button'}`}
                  >
                  <GoSync/>
                </span>
              </Button> 
                {syncEventByIdFeedback && <span className={`parameter-control__sync-feedback parameter-control__sync-feedback--${syncEventByIdFeedback.success ? 'success' : 'faild'}`}>{syncEventByIdFeedback.message}</span>}
          </span>
          <span>
            <TextField 
                getInputValue={(value) => setObjectId({id: value, error: false})}
                labelValue='ObjectID'
                type='text'
               
                />
          </span>
        </LineFlex>
      </LineFlex>


      {/* sync all event button */}
      <LineFlex pX='3.5vw' pY='3.5vw 0' flexDir="column">
          <LineFlex pX='1rem'>
            <Details title="סנכרון כל האירועים" >
            <p>פעולה זו תבצע סנכרון מלא של כלל האירועים במערכת  מ-מערכת MyBussiness   ל-בסיס הנתונים הנוכחי</p>
            </Details>
          </LineFlex>

          <LineFlex>
            <span onClick={() => syncEventsHandler()}>
                <Button color='primary' disabled={syncEvents && true} disabledEffect={false}>
                  {buttonEventSyn_conditionalRendering('sync-all-events')}
                  <span 
                    className={`parameter-control__on-action ${!syncEvents && 'parameter-control__sync-button'}`}
                    >
                    <GoSync/>
                  </span>
                </Button> 
                {syncCategoriesFeedback && <span className={`parameter-control__sync-feedback parameter-control__sync-feedback--${syncEventsFeedback.success ? 'success' : 'faild'}`}>{syncEventsFeedback.message}</span>}
            </span>
          </LineFlex>
      </LineFlex>
            {/* sync all categories button */}
            <LineFlex pX='3.5vw' pY='3.5vw 0' flexDir="column">
          <LineFlex pX='1rem'>
            <Details title="סנכרון קטגריות" >
            <p>פעולה זו תבצע סנכרון מלא של כלל הקטגריות במערכת  מ-מערכת MyBussiness   ל-בסיס הנתונים הנוכחי</p>
            </Details>
          </LineFlex>

          <LineFlex>
            <span onClick={() => syncCategoriesHandler()}>
                <Button color='primary' disabled={syncCategories && true} disabledEffect={false}>
                  {buttonEventSyn_conditionalRendering('sync-all-categories')}
                  <span 
                    className={`parameter-control__on-action ${!syncCategories && 'parameter-control__sync-button'}`}
                    >
                    <GoSync/>
                  </span>
                </Button> 
                {syncCategoriesFeedback && <span className={`parameter-control__sync-feedback parameter-control__sync-feedback--${syncCategoriesFeedback.success ? 'success' : 'faild'}`}>{syncCategoriesFeedback.message}</span>}
            </span>
          </LineFlex>
      </LineFlex>
    </div>
  )
}
