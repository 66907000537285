import React from 'react'
// import Logo from  '../../../assets/images/screens/logo.png'

const Home: React.FC = () => {
    return (
        // <img className='logo' src={Logo} alt="" />
        <div></div>
    )
}

export default Home
