
import React from 'react'

const SearchTags = () => {
  return (
    <div>SearchTags</div>
  )
}

export default SearchTags


// original code:
// import React from 'react'

// import ContentScreen from './ContentScreen'
// import './Styles/Style.SearchTags.css'

// export default function SearchTags() {
//   return (
//     <div className='mainSearchPage'>
//       {/* <ContentScreen titleScreen='White List'/> */}
//       {/* <ContentScreen titleScreen='Black List'/> */}
      
//     </div>
//   )
// }
