import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavBar: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className='nav-bar'>
            <button onClick={() => navigate("/")} className='nav-bar__home-page nav-bar__nav-btn'>דף ראשי</button>
            <button onClick={() => navigate("/push")} className='nav-bar__push-notification-page nav-bar__nav-btn'>מערכת הודעות דחיפה</button>
            <button onClick={() => navigate("/search-tags")} className='nav-bar__search-page nav-bar__nav-btn'>מילות חיפוש</button>
            <button onClick={() => navigate("/reports")} className='nav-bar__reports-page nav-bar__nav-btn'>דוחות</button>
            <button onClick={() => navigate("/parameter-control")} className='nav-bar__parameters-page nav-bar__nav-btn'>פרמטרים</button>
        </div>
    )
}

export default NavBar
