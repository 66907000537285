import React, {useState} from 'react';


interface IProps {
  getInputValue: (value: any) => void
  labelValue: string
  type: string
  disabled?: boolean
  required?: boolean
  error?: any
  max?: number
  color?: string
  marginLeft?: string
  fullWidth?: boolean
} 

const InputText: React.FC<IProps> = ({getInputValue, labelValue, disabled, required, error, type, max, color, fullWidth, marginLeft}) => {

  const [clickMode, setClickMode] = useState(false)
  const [errorValidate, setErrorValidate] = useState('')
  const [inpValue, setInpValue] = useState('')

  const onInputChange = (inputValue: string) => {
    setInpValue(inputValue)
    // // Rising event - send out the input value data.
    getInputValue(inputValue.trim())
    // Set the label location when input obtain value.
    if(inputValue.length > 0) {
      setClickMode(true)
      setErrorValidate('')
    } else {
      setClickMode(false)
      if(required) {
        setErrorValidate(error)
      } 
    }
  }
  
  const clickModeHanlder = (value: string) => {
    if(value === 'label') {
      setClickMode(true)
    }
    if(value.length > 0) {
      setClickMode(true)
    } else {
      setClickMode(false)
    }

    // if( inputValue.length > 0 ) setErrorValidate('')
  }

  const errorValidation_conditionalRendering = () => {
    if(errorValidate) {
      return <span className='text-field-component__error-validate'>{errorValidate}</span>
    }
  }

  const labelPosition_conditionalRendering = () => {
    if(labelValue) {
      return (
        <>
        <label className={`text-field-component__label ${clickMode && 'label-open'}`}  onClick={(e) => clickModeHanlder('label')}>{labelValue}</label>
        <span className={`text-field-component__label-background ${clickMode && 'label-background-open'}`}>{labelValue}</span>
        </>
      )
    }
  }


  return (
    <div  
      className={`text-field-component ${disabled && 'disabled-text-field-component'} ${errorValidate && 'error-text-field-component'} text-field-component__border-color-${color ? color : ''}`}
      style={{ width: fullWidth ? 'inherit' : '', maxWidth: max ? `unset` : '', marginLeft: marginLeft ? marginLeft : ''}}
    >
      <input 
          value={inpValue}
          type={type} 
          className={`text-field-component__input-field`} 
          onBlur={(e) => clickModeHanlder(e.target.value)} 
          onClick={() => setClickMode(true)} 
          onChange={(e) => onInputChange(e.target.value)}
          maxLength={max}
      />
      {errorValidation_conditionalRendering()} 
      {labelPosition_conditionalRendering()}
    </div>
  )
}

export default InputText