import React from 'react'
import { BsPatchQuestionFill } from 'react-icons/bs';


export interface IProps {
  title?: string;
}

const LineFlex: React.FC<IProps>  = ({children, title}) => {
  return (
    <div className='details-component'>
      <p className='details-component__title'><span className='details-component__icon'><BsPatchQuestionFill/></span> {title}</p>
      {children}
    </div>
  )
}

export default LineFlex

