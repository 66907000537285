import React from 'react'

interface IProps {
    children: React.ReactNode
}

const PageHeader: React.FC<IProps> = ({children}) => {
  return (
    <h1 className='page-header-common'>{children}</h1>
  )
}

export default PageHeader