import React from 'react'

export interface IProps {
  disabled?: boolean;
  fullWidth?: boolean;
  color?: string;
  disabledEffect?: boolean;
}

const Button: React.FC<IProps>  = ({disabled, fullWidth, color, children, disabledEffect}) => {
  return (
    <button
    className={`button-component ${color ? `bc--${color}` : ''} ${disabledEffect === false && 'button-component__disabledEffect-false'}`} 
    disabled={disabled}
    style={fullWidth ? { width: '100%' } : {marginLeft: '20px', width: 'auto'}}
    >{children}
    </button>
    
  )
}

export default Button

