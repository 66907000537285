import axios from "axios";
// import { fetchApiInstance } from "../utils/fatchApiInstance";

// @desc    sync all events
// @route   [GET] /events/sync-all-events
// @access  Private
// @payload [no payload]
// @param   [no params]
export async function SyncAllEventsService() {
    try {
        const { data } = await axios.get('https://api.bigtime60app.co.il/event/sync-all-events')
        return data;
    }  catch (err: any) {
        if( err.response ) {
            return err.response.data.message
        }
    }
}

export async function SyncAllCategoriesService() {
    try {
        const { data } = await axios.get('https://api.bigtime60app.co.il/category/sync-all-categories')
       console.log('DATA',data);
       
        return data;
    }  catch (err: any) {
        if( err.response ) {
            return err.response.data.message
        }
    }
}

// @desc    sync event by id
// @route   [GET] /events/sync-event-by-id
// @access  Private
// @payload [no payload]
// @param   [no params]
export async function SyncEventByIdService(eventId: string) {
    try {
        const { data } = await axios.get(`https://api.bigtime60app.co.il/event/sync-event-by-id/${eventId}`)
        console.log('SyncedEventByID:',data);
        
        return data;
    }  catch (err: any) {
        if( err.response ) {
            return err.response.data.message
        }
    }
}

export async function getAllEvents() {
    try {
        const data  = await axios.get('https://api.bigtime60app.co.il/event')
        console.log('inside getAllEvents',data);
        
        return data;
    }  catch (err: any) {
        if( err.response ) {
            return err.response.data.message
        }
    }
}


