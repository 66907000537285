import React from 'react'

export interface IProps {
  value?: string;
  flexX?: string;
  flexY?: string;
  pX?: string;
  pY?: string;
  flexDir?: any;
}

const LineFlex: React.FC<IProps>  = ({flexX, flexY, children, pX, pY, flexDir}) => {
  return (
    <div 
    style={{
        display: 'flex',
        flexDirection: flexDir ? flexDir : 'row',
        justifyContent: flexX ? flexX : 'flex-start', 
        alignItems: flexY ? flexY : 'flex-start',
        direction: 'rtl',
        padding: `${pX ? pX : '0px'} ${pY ? pY : '0px'}`
    }}>{children}</div>
  )
}

export default LineFlex

