import React from 'react'

const PushNotifications = () => {
  return (
    <div>PushNotifications</div>
  )
}

export default PushNotifications



// original code:
// import React, {useState} from 'react'
// import Button from '../../common/button/Button';
// import Table from '../../common/table/Table'
// import TableBody from '../../common/table/TableBody';
// import TableBodyCell from '../../common/table/TableBodyCell';
// import TableHead from '../../common/table/TableHead';
// import TableHeadCell from '../../common/table/TableHeadCell';
// import TableRow from '../../common/table/TableRow';
// import LineFlex from '../../layout/line-flex.layout/LineFlex'
// import Modal from '../../templates/modal.template/Modal';
// // icons 
// import { FaEdit, FaPlusCircle } from "react-icons/fa";

// const items = [
//   {
//     itemName: 'פרוזן יוגורט',
//     id: 1,
//     sendTo: ['evyatar@gmail.com'],
//     willSend: {
//       item: 'גלידה',
//       timeWillSend: '12:00',
//     },
//     amountReceived: 55,
//     amountPushClicks: 65
//   },
//   {
//     itemName: 'אבטיח',
//     id: 2,
//     sendTo: ['shlomi@gmail.com', 'yakov@gmail.com'],
//     willSend: {
//       item: 'גרעיני אבטיח',
//       timeWillSend: '15:00',
//     },
//     amountReceived: 12,
//     amountPushClicks: 95
//   },
//   {
//     itemName: 'אבטיח',
//     id: 3,
//     sendTo: ['shlomi@gmail.com', 'yakov@gmail.com'],
//     willSend: {
//       item: 'גרעיני אבטיח',
//       timeWillSend: '15:00',
//     },
//     amountReceived: 12,
//     amountPushClicks: 95
//   },
//   {
//     itemName: 'אבטיח',
//     id: 5,
//     sendTo: ['shlomi@gmail.com', 'yakov@gmail.com'],
//     willSend: {
//       item: 'גרעיני אבטיח',
//       timeWillSend: '15:00',
//     },
//     amountReceived: 12,
//     amountPushClicks: 95
//   },
//   {
//     itemName: 'אבטיח',
//     id: 7,
//     sendTo: ['shlomi@gmail.com', 'yakov@gmail.com'],
//     willSend: {
//       item: 'גרעיני אבטיח',
//       timeWillSend: '15:00',
//     },
//     amountReceived: 12,
//     amountPushClicks: 95
//   },
//   {
//     itemName: 'אבטיח',
//     id: 8,
//     sendTo: ['shlomi@gmail.com', 'yakov@gmail.com'],
//     willSend: {
//       item: 'גרעיני אבטיח',
//       timeWillSend: '15:00',
//     },
//     amountReceived: 12,
//     amountPushClicks: 95
//   },
//   {
//     itemName: 'אבטיח',
//     id: 9,
//     sendTo: ['shlomi@gmail.com', 'yakov@gmail.com'],
//     willSend: {
//       item: 'גרעיני אבטיח',
//       timeWillSend: '15:00',
//     },
//     amountReceived: 12,
//     amountPushClicks: 95
//   },
//   {
//     itemName: 'אבטיח',
//     id: 10,
//     sendTo: ['shlomi@gmail.com', 'yakov@gmail.com'],
//     willSend: {
//       item: 'גרעיני אבטיח',
//       timeWillSend: '15:00',
//     },
//     amountReceived: 12,
//     amountPushClicks: 95
//   },
// ];

// const PushNotifications: React.FC = () => {

//   const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  
//   const modelOpen_conditionalRendering = () => {
//     if (modalIsOpen) {
//       return (
//         <Modal 
//       modalType='CREATE' // update, create {AUTO FROM THE BUTTON.}
//       // modalType='UPDATE' // update, create {AUTO FROM THE BUTTON.}
//       />
//       )
//     }
//   }
//   return (
//     <div>


//       <LineFlex pX='3.5vw' pY='3.5vw'>
//         <span onClick={() => setModalIsOpen(!modalIsOpen)}> <Button>הוסף חדש <FaPlusCircle/></Button> </span>
//       </LineFlex>

//       {/* only for testing - to deaign the component  */}
//         <span>{modelOpen_conditionalRendering()}</span>
      
      
//       <LineFlex  pX='0' pY='3.5vw'>
//         <Table fullWidth>
//           <TableHead>
//             <TableRow>
//               <TableHeadCell>מה נשלח</TableHeadCell>
//               <TableHeadCell>אל מי נשלח</TableHeadCell>
//               <TableHeadCell>מה צפוי להשלח</TableHeadCell>
//               <TableHeadCell>מתי צפוי</TableHeadCell>
//               <TableHeadCell>כמה קיבלו</TableHeadCell>
//               <TableHeadCell>כמה לחצו על פוש</TableHeadCell>
//               <TableHeadCell>פעולות</TableHeadCell>
//             </TableRow>
//           </TableHead>
          
//           <TableBody>
//             {items.map((item:any) => (
//               <TableRow key={item.id}>
//               <TableBodyCell>{item.itemName}</TableBodyCell>
//               <TableBodyCell>{item.sendTo}</TableBodyCell>
//               <TableBodyCell>{item.willSend.item}</TableBodyCell>
//               <TableBodyCell>{item.willSend.timeWillSend}</TableBodyCell>
//               <TableBodyCell>{item.amountReceived}</TableBodyCell>
//               <TableBodyCell>{item.amountPushClicks}</TableBodyCell>
//               <TableBodyCell><span onClick={() => setModalIsOpen(!modalIsOpen)}><Button color='primary'>עריכה <FaEdit/></Button> </span></TableBodyCell>
//             </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </LineFlex>

      
//     </div>
//   )
// }

// export default PushNotifications