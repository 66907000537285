import { BrowserRouter, Routes, Route } from "react-router-dom";
// screens 
import Home from "../components/screens/home.screen/Home";
import ParameterControl from "../components/screens/parameter-control.screen/ParameterControl";
import PushNotifications from "../components/screens/push-notifications.screen/PushNotifications";
import Reports from "../components/screens/reports.screen/Reports";
import SearchTags from "../components/screens/search-tags.screen/SearchTags";
// static 
import NavBar from "../components/static/navbar.static/NavBar";
import Logo from  '../assets/images/screens/logo.png'


function App() {

  return (
      <div className="App">
        <BrowserRouter>
          <header>
          <img className='logo-nav-bar' src={Logo} alt="" />
            <NavBar />
          </header>
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/push" element={<PushNotifications />} />
              <Route path="/search-tags" element={<SearchTags />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/parameter-control" element={<ParameterControl/>} />
            </Routes>
          </main>
       </BrowserRouter>
      </div>
  );
}

export default App;
